<template>
  <div class="searchBox">
    <img @click="close" class="cha" src="../assets/img/ding/cha.png" alt="" />
    <div class="searchMain">
      <p class="Tit">移交</p>
      <!-- 合作单位 -->
      <div class="part1">
          <div class="title"><div class="tit-left"></div><div class="tit-right">基础信息</div></div>
      </div>
      <div class="yuitem">
        <label class="yulabel" for="">合作单位</label>
        <span class="yuinput">{{ xieyiTit }}</span>
      </div>
      <div class="yuitem">
        <label class="yulabel" for="">订单情况</label>
        <div class="content">
          <div class="c-l">本月</div>
          <div class="c-m">{{ num }}单</div>
          <div class="c-r">{{ money }}元</div>
        </div>
        <div class="content gray">
          <div class="c-l">上月</div>
          <div class="c-m">{{ num_last }}单</div>
          <div class="c-r">{{ money_last }}元</div>
        </div>
      </div>
      <div class="yuitem">
        <label class="yulabel" for="">移交日期</label>
        <el-date-picker
                class="yuTime"
                v-model="time"
                type="date"
                placeholder="请选择时间"
                size="default"
              >
              </el-date-picker>
      </div>
      <div class="yuitem">
          <label class="yulabel" for="">移交对象</label>
          <el-select v-model="to_saleman" placeholder="请选择移交业务员">
                <el-option
                  v-for="item in salelist"
                  :key="item.uid"
                  :label="item.name"
                  :value="item.uid"
                  :disabled="item.uid == old_saleman?true:false"
                >
                </el-option>
          </el-select>
      </div>
      <div class="yuitem">
          <div class="yulabel" style="text-align: center;">备注</div>
          <el-input
              class="text"
              v-model="remark"
              type="textarea"
              placeholder="如帐单情况、注意事项等"
              :rows="4"
            />
      </div>

      <div class="part2">
          <div class="title"><div class="tit-left"></div><div class="tit-right">历史消息</div></div>
      </div>

      <div class="yuxinBox" ref="newbox">
        <!-- 信息 -->
        <table class="yutable" border="1" cellspacing="0">
          <tr class="yutit">
            <th class="a">原业务员</th>
            <th class="b">新业务员</th>
            <th class="c">移交日期</th>
            <th class="d">备注</th>
          </tr>
          <template v-if="oldlist.length">
            <tr
              :class="
                (index % 2 == 0 && !changecolor) ||
                (index % 2 !== 0 && changecolor)
                  ? 'bg_color'
                  : 'bg_color2'
              "
              v-for="(item, index) in oldlist"
              :key="index"
            >
              <td>{{ item.old }}</td>
              <td>{{ item.new }}</td>
              <td>{{ item.date }}</td>
              <td>{{ item.remark }}</td>
            </tr>
          </template>
        </table>
      </div>

      <!-- 按钮 -->
      <div class="btn">
        <button class="set" @click="bao">保存</button>
        <button class="cancel" @click="close">取消</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessageBox } from "element-plus";
import * as api from "../api/index";
import search from "./search.vue";
import Yusearch from "./yusearch.vue";
import xieyiadd from "./xieyiadd.vue";
import xieyiedit from "./xieyiedit.vue";
import { fa } from "element-plus/lib/locale";
export default {
  components: { search, Yusearch,xieyiadd,xieyiedit },
  props: ["yijiaoCids"],
  data() {
    return {
      yjcid:this.yijiaoCids,
      xieyiTit:'宁波大的的撒的撒啊倒萨啊倒萨的撒的撒的撒',
      num:15,
      money:12000,
      num_last:3000,
      money_last:200000,
      to_saleman:'',
      old_saleman:'',
      time:'',
      salelist:[
        {
          uid:1,
          name:'测试',
        },
        {
          uid:2,
          name:'测试2',
        },
      ],
      remark:'',
      oldlist:[{
        old:'大撒大撒',
        new:'大大撒',
        time:'2024-04-01',
        remark:'就测试测试就测试测试就测试测试就测试测试就测试测试就测试测试就测试测试'
      },{
        old:'大撒大撒',
        new:'大大撒',
        time:'2024-04-01',
        remark:'就测试测试就测试测试就测试测试就测试测试就测试测试就测试测试就测试测试'
      },{
        old:'大撒大撒',
        new:'大大撒',
        time:'2024-04-01',
        remark:'就测试测试就测试测试就测试测试就测试测试就测试测试就测试测试就测试测试'
      },{
        old:'大撒大撒',
        new:'大大撒',
        time:'2024-04-01',
        remark:'就测试测试就测试测试就测试测试就测试测试就测试测试就测试测试就测试测试'
      },{
        old:'大撒大撒',
        new:'大大撒',
        time:'2024-04-01',
        remark:'就测试测试就测试测试就测试测试就测试测试就测试测试就测试测试就测试测试'
      },{
        old:'大撒大撒',
        new:'大大撒',
        time:'2024-04-01',
        remark:'就测试测试就测试测试就测试测试就测试测试就测试测试就测试测试就测试测试'
      }],
    };
  },

  created() {
    //this.getlist();
    console.log("打印判断---", this.yijiaoCids);
    this.getinfo();
  },

  methods: {
    async getinfo()
    {
        let res = await api.getyjinfo(this.yijiaoCids);

        let data = res.data;

        this.xieyiTit = data.name;
        this.num      = data.num;
        this.money    = data.money;
        this.num_last = data.num_last;
        this.money_last = data.money_last;
        this.salelist = data.sale;
        this.oldlist  = data.logs;
        this.old_saleman = data.old_saleman;
        this.time     = data.time;

        console.log(res);
    },
    close() {
      this.$emit("closeyj");
      this.to_saleman = '';
      this.remark = '';
    },
    async bao()
    {
        if(this.to_saleman == '')
        {
            this.$message.warning("请选择移交的业务员");
            return false;
        }

        ElMessageBox.confirm("是否确认移交吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          // let data = {
          //   cid: this.yijiaoCids,
          //   saleman:this.to_saleman,
          //   remark:this.remark
          // };
          let xiures = await api.postyj(this.yijiaoCids,this.to_saleman,this.remark,this.time);
          console.log("确定---", xiures);
          this.$message.success("提交成功");
          this.getinfo();
          this.to_saleman = '';
          this.remark = '';
        })
        .catch(() => {
          this.$message.info("取消提交");
        });

        
    }
  },
  watch: {    
    yijiaoCids(newval) {
      console.log(111);
      this.yjcid = newval;
    }
  },
};
</script>

<style lang="less" scoped>
.searchBox {
  width: 580px;
  background-color: skyblue;
  position: absolute;
  top: -80px;
  right: 0;
  z-index: 100;
}
.searchMain {
  width: 580px;
  height: 920px;
  background-color: #fff;
  padding-top: 40px;
  padding-left: 30px;
  box-sizing: border-box;
  box-shadow: 0px -15px 20px rgba(179, 189, 195, 0.4);
}
.cha {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 65px;
  left: -40px;
}
.Tit{
  width: 40px;
  height: 28px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 20px;
  color: #171746;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none
}
.part1{
  margin-top:32px;
}
.title{
  line-height: 22px;
  height:22px;
}
.tit-left{
  width: 2px;
  height: 14px;
  background: #2C68FF;
  border-radius: 2px 2px 2px 2px;
  float:left;
  margin-top:4px;
  margin-right:10px;
}
.tit-right{
  width: 64px;
  height: 22px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 16px;
  color: #44444F;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  float:left;
}
.searchTit {
  font-size: 20px;
  color: #2a3346;
  font-weight: bold;
  box-sizing: border-box;
}
.yuitem {
  margin-top: 24px;
  display: flex;
  align-items: center;
}
.yulabel {
  width: 80px;
  height: 20px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.yuinput {
  width: 440px;
  height: 20px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #44444F;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
.yuinput::-webkit-input-placeholder {
  color: #c0c4cc;
}

.content{
  width: 222px;
  height: 35px;
  line-height:22px;
  background: #FFFFFF;
  border-radius: 0px 0px 0px 0px;
  border: 1px solid #CCCCCC;
  float:left;
}

.gray{
  background: #F5F5F5 !important;
  border-left:none;
}

.c-l{
  width:55px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #44444F;
  text-align: center;
  font-style: normal;
  text-transform: none;
  line-height: 35px;
  height:100%;
  border-right: 1px solid #CCCCCC;
  float:left;
}

.c-m{
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #44444F;
  text-align: center;
  font-style: normal;
  text-transform: none;
  width:70px;
  line-height: 35px;
  height:100%;
  border-right: 1px solid #CCCCCC;
  float:left;
}

.c-r{
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #44444F;
  text-align: center;
  font-style: normal;
  text-transform: none;
  line-height: 35px;
  height:100%;

}
.el-select{
  width:334px;
  height:35px;
  line-height: 35px;
}

.text{
  width: 335px;
  height: 88px;
  background: #FFFFFF;
  // border-radius: 4px 4px 4px 4px;
  // //border: 1px solid #E2E2EA;
}

.part2{
  margin-top:48px;
}
.a,.b{
  width:90px;
}
.c{
  width:110px;
}
.d{
  width:240px;
}
.mapImg {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 5px;
}
.add {
  float: left;
  width: 30px;
  height: 32px;
  margin-left: 50px;
  cursor: pointer;
}
// .bite {
//   width: 380px;
//   margin-right: 10px;
// }
.yuxinBox {
  width: 524px;
  max-height: 300px;
  margin-top: 12px;
  overflow-y: scroll;

}
.yuxinBox::-webkit-scrollbar {
  width: 1px;
  height: 10px;
}
.foundyuxinBox {
  max-height: 800px;
}
.yutable {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  border: 1px solid #CCCCCC !important;
}
.yutable td {
  font-family: PingFang SC, PingFang SC;
font-weight: 400;
font-size: 14px;
color: #44444F;
text-align: center;
font-style: normal;
text-transform: none
}
.yubtn {
  width: 50px;
  height: 32px;
  background-color: #2c68ff;
  color: #fff;
  margin-left: 20px;
  border: 0;
  cursor: pointer;
}
.yubtn1 {
  width: 60px;
  height: 32px;
  background-color: #2c68ff;
  color: #fff;
  margin-left: 440px;
  border: 0;
  cursor: pointer;
}
.xiubtn {
  width: 50px;
  height: 32px;
  margin-left: 20px;
  border: 1px solid #e0e0e0;
  cursor: pointer;
}
.btn {
  margin-top: 46px;
  // padding-left: 10px;
  box-sizing: border-box;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border: none;
  border-radius: 2px;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  margin-left: 20px;
  border-radius: 2px;
  vertical-align: top;
}
.yutit {
  height: 32px;
  background-color: #f5f5f5;
}
// .yutxt {
//   height: 50px;
// }
.gai,
.shan {
  width: 32px;
  height: 20px;
  border: 0;
  font-size: 12px;
  cursor: pointer;
}
.gai {
  background-color: #2c68ff;
  color: #fff;
}
.shan {
  margin-left: 10px;
}
.renwu,
.mudi {
  width: 90px;
}
.yikou {
  width: 110px;
}
.operation {
  width: 240px;
}
.select-panel {
  // height: 100px;
  position: absolute;
  top: 35px;
  margin-left: 80px;
  z-index: 999;
  background-color: #fff;
  box-shadow: 2px 3px 27px rgba(166, 177, 186, 0.53);
  // background-color: pink;
}
.input-norm1 {
  float: left;
  width: 46px;
  height: 32px;
  background: #ffffff;
  margin-left: 10px;
  outline: none;
  border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px 0 0 2px;
  text-align: center;
  box-sizing: border-box;
  border-right: 0;
}
.ding {
  position: relative;
}
ul {
  list-style: none;
}
li {
  margin: 9px;
  margin-left: 2px;
  cursor: pointer;
}
tr:hover {
  background-color: #f9fafe !important;
}
.bg_color {
  height: 50px;
  background-color: #ffffff !important;
}
.bg_color2 {
  height: 50px;
  background-color: #fafafa !important;
}
.jishi {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 12px;
}
.jishiint {
  width: 80px;
  height: 32px;
  font-size: 12px;
  font-weight: 400;
  padding-left: 12px;
  // text-align: center;
  box-sizing: border-box;
  border: 1px solid #dadfe6;
  outline: none;
}
.yuan {
  width: 32px;
  height: 30px;
  box-sizing: border-box;
  background-color: #f7f9fb;
  text-align: center;
  line-height: 30px;
  position: absolute;
  top: 1px;
  right: 1px;
}

.priceTit,
.juli {
  font-size: 14px;
}


</style>
