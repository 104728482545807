<template>
  <div class="hezuobox">
    <div class="helist">
      <el-table :data="joinList" stripe>
        <el-table-column label="合作单位名称" width="300">
          <template #default="scope">
            <i @click="jion(scope.row.cid)" class="xiangqing">{{
              scope.row.name
            }}</i>
          </template>
        </el-table-column>
        <el-table-column label="简称" width="250">
          <template #default="scope">
            {{ scope.row.short }}
          </template>
        </el-table-column>
        <el-table-column label="联系方式" width="200">
          <template #default="scope">
            {{ scope.row.contact
            }}<button
              @click="call(scope.row.mobile, '')"
              class="call"
              v-show="getEmic != ''"
            ></button>
          </template>
        </el-table-column>
        <el-table-column label="到期日期" width="200">
          <template #default="scope">
            <div :style="scope.row.color" @click="lookxieyi(scope.row.pdf)">{{ scope.row.xieyi }}</div>
          </template>
        </el-table-column>
        <el-table-column label="地址" width="450">
          <template #default="scope">
            {{ scope.row.address }}
          </template>
        </el-table-column>
        <el-table-column label="业务员" width="200">
          <template #default="scope">
            <strong v-html="scope.row.saleman"></strong>
          </template>
        </el-table-column>
        <el-table-column label="接入时间" width="200">
          <template #default="scope">
            {{ scope.row.time }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" v-if="!getlook">
          <template #default="scope">
            <!-- <el-button
              link
              type="primary"
              size="small"
              @click="jion(scope.row.cid)"
              >查看</el-button
            > -->
            <el-button
              link
              type="primary"
              size="small"
              v-if="scope.row.status == 1 && scope.row.canManage && !getlook"
              @click="dodo(scope.row.cid, 0)"
              >已启用</el-button
            >
            <el-button
              link
              type="primary"
              size="small"
              style="background: #bcbcbc; border: 0"
              v-if="scope.row.status == 0 && scope.row.canManage && !getlook"
              @click="dodo(scope.row.cid, 1)"
              >已禁用</el-button
            >
            <el-button
              link
              type="primary"
              size="small"
              @click="serve(scope.row.cid, id)"
              v-if="scope.row.canManage && !getlook"
              >价格</el-button
            >
            <el-button
              link
              type="primary"
              size="small"
              @click="plain(scope.row.cid)"
              v-if="scope.row.canManage && !getlook"
              >人员</el-button
            >
            <el-button
              link
              type="primary"
              size="small"
              v-if="scope.row.canCommission && !getlook"
              @click="xiao(scope.row.cid) "
              >营销</el-button
            >
            <el-button
              link
              type="primary"
              size="small"
              v-if="!getlook"
              @click="guize(scope.row.cid)"
              >空驶</el-button
            >
            <el-button
              link
              type="primary"
              size="small"
              v-if="!getlook"
              @click="pian(scope.row.cid)"
              >照片</el-button
            >
            <el-button
              link
              type="primary"
              size="small"
              v-if="!getlook"
              @click="
                yushe(
                  scope.row.cid,
                  scope.row.short ? scope.row.short : scope.row.name
                )
              "
              >预设</el-button
            >
            <el-button
              link
              type="primary"
              size="small"
              v-if="!getlook && scope.row.canGongdan"
              @click="
                dl(
                  scope.row.cid, scope.row.short ? scope.row.short : scope.row.nam
                )
              "
              >工单</el-button>
              <el-button
              link
              type="primary"
              size="small"
              v-if="!getlook && scope.row.canZhangdan"
              @click="
                zd(scope.row.cid,scope.row.name)
              "
              >账单</el-button>

              <el-button
              link
              type="primary"
              size="small"
              v-if="!getlook"
              @click="
                xieyi(scope.row.cid,scope.row.name)
              "
              >协议</el-button>

              <el-button
              link
              type="primary"
              size="small"
              v-if="!getlook"
              @click="
                gsyj(scope.row.cid,scope.row.name)
              "
              >移交</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="xieyi-tixing"> <div class="xieyi-label">协议提醒：</div> <div class="xieyi-t5">服务中<b @click="changexieyi(0)">{{ total2['num5'] }}</b>家</div> <div class="xieyi-t1">即将到期<b @click="changexieyi(1)">{{ total2['num1'] }}</b>家</div> <div class="xieyi-t2">本月到期<b @click="changexieyi(2)">{{ total2['num2'] }}</b>家</div> <div class="xieyi-t3">已过期<b @click="changexieyi(3)">{{ total2['num3'] }}</b>家</div> <div class="xieyi-t4">无协议<b @click="changexieyi(4)">{{ total2['num4'] }}</b>家</div> </div>
    <!-- 分页器 -->
    <div class="jinXing-sorter">

      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="datas.perpage"
        @prev-click="prev_view"
        @next-click="next_view"
        @size-change="size_view"
        @current-change="current_view"
      >
      </el-pagination>
    </div>
    <bill v-if="zdshow" @zguanbi="zguanbi" :zdcid="zdcid" :zdname="zdname"></bill>
    <exception v-if="shujuyin" @guanbi="guanbi"></exception>
    <manage :iscom="com" :srccid="srccid"></manage>
    <price :israte="rate" :resHeZuo="resHeZuo" :cid="cid"></price>
    <join-check :isJoin="over" :cid="cid" @datass="shua"></join-check>
    <program :isxiao="ying" :commissioncid="comcid"></program>
    <rule
      :isgui="gui"
      :konggui="konggui"
      :kongguilist="kongguilist"
      :guiId="guiId"
      @closegui="closegui"
    ></rule>
    <photo :isphoto="zhao" :photoccid="pcid"></photo>
    <presets
      :isyu="yu"
      :yusheTitP="yusheTit"
      :yushecid="yucid"
      @closeyu="closeyu"
      v-if="preIsShow"
      :isreadonly="true"
    ></presets>
    <xieyi
      :isxieyi="xy"
      :xieyiTit="xyTit"
      :xieyiCid="xyCid"
      @closexy="closexy"
      v-if="xyIsShow"
    ></xieyi>
    <yijiao
      :yijiaoCids="yijiaoCid"
      @closeyj="closeyj"
      v-if="yijiaoShow"
    ></yijiao>
  </div>
</template>

<script>
import manage from "./manage.vue";
import bill from "./bill.vue";
import Marketing from "./marketing.vue";
import Price from "./price.vue";
import joinCheck from "./joinCheck.vue";

import * as api from "../api/join.js";
import * as order from "../api/order.js";
import Program from "./program.vue";
import Rule from "./rule.vue";
import Photo from "./photo.vue";
import xieyi from "./xieyi.vue";
import yijiao from "./yijiao.vue";
import * as ola from "../api/olawebsock.js";
import Presets from "./Presets.vue";
import { ElMessageBox } from "element-plus";
import Exception from '../components/exception.vue';
export default {
  props: {
    isfresh: "",
    bkeyword: "",
    bsaleman: "",
    bcompanytype: "",
    bxieyi:'',
    isState:'',
  },
  components: {
    manage,
    Price,
    Marketing,
    joinCheck,
    Program,
    Rule,
    Photo,
    Presets,
    bill,
    xieyi,
    yijiao
  },
  data() {
    return {
      com: 0,
      rate: 0,
      over: 0,
      ying: 0,
      gui: false,
      zhao: 0,
      yu: 0,
      yusheTit: "",

      mark: false,
      joinList: [],
      res: {},
      knowList: [],
      id: "",
      resHeZuo: {},
      resPrice: [],
      priceList: [],
      personList: [],
      cid: "",
      // cid:0,
      srccid: "",
      comcid: "",
      yucid: "",
      total: 100,
      datas: {
        page: 1,
        perpage: 7,
        keyword: "",
        saleman: "",
        companytype: "",
        status:2,
        xieyi:0,
      },
      konggui: [],
      kongguilist:[],
      guiId: "",
      pcid: "",
      emicreg: "",
      preIsShow: false,
      zdshow:false,
      zdcid:'',
      zdname:'',

      xyTit:'',
      xyCid:'',
      xy:0,
      xyIsShow:false,
      total2:{},
      yijiaoShow:false,
      yijiaoCid:'',
    };
  },

  async created() {
    this.res_joinList(this.datas);
  },

  methods: {
    lookxieyi(txt)
    {
      if(txt != '')
      {
        window.open(txt,'_blank');
      }
    },
    changexieyi(e)
    {
        this.datas.xieyi = e + 1;
        this.datas.page = 1;
        this.res_joinList(this.datas);
        this.$emit("backxieyi",e + 1);
    },
    async res_joinList(data) {
      let res_joinList = await api.joinList(data);
      console.log("合作商列表：", res_joinList);
      this.joinList = res_joinList.data;
      this.total = parseInt(res_joinList.total);
      this.total2 = res_joinList.total2;
    },
    async plain(id) {
      this.com++;
      if (this.com == 3) {
        this.com = 1;
      }
      this.srccid = id;
    },
    closegui() {
      this.gui = false;
      this.konggui = "";
    },
    closeyu() {
      // this.yu = false;
      this.preIsShow = false;
    },
    closexy() {
      // this.yu = false;
      this.res_joinList(this.datas);
      this.xyIsShow = false;
    },
    async dl(cid,name)
    {
      ElMessageBox.confirm("确认是否导出上个月工单照", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          window.open('https://www-test.nituowola.com/site/p-d-list?cid='+cid,'_blank')
        })
        .catch(() => {
          this.$message.info("取消导出");
        });
 
    },
    async zd(cid,name)
    {
      this.zdcid = cid;
      this.zdname = name;
      this.zdshow = true;
      // ElMessageBox.confirm("确认是否导出上个月账单", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // })
      //   .then(() => {
      //     window.open('https://www-test.nituowola.com/site/x08081?cid='+cid,'_blank')
      //   })
      //   .catch(() => {
      //     this.$message.info("取消导出");
      //   });
 
    },
    async xieyi(cid,name)
    {
      this.xyCid = cid;
      this.xyTit = name;
      this.xyIsShow = true;
      // ElMessageBox.confirm("确认是否导出上个月账单", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // })
      //   .then(() => {
      //     window.open('https://www-test.nituowola.com/site/x08081?cid='+cid,'_blank')
      //   })
      //   .catch(() => {
      //     this.$message.info("取消导出");
      //   });
 
    },
    async guize(cid) {
      // this.gui++;
      // if (this.gui == 3) {
      //   this.gui = 1;
      // }
      this.gui = true;
      let res = await api.getRule(cid);
      this.konggui = res.data;
      this.kongguilist = res.rescueTypeList;
      this.guiId = cid;
      console.log("空驶规则信息------", res.data);
      console.log("合作规则cid-----", cid);
    },
    xiao(cid) {
      this.ying++;
      this.comcid = cid;
    },
    pian(cid, name) {
      this.zhao++;
      this.pcid = cid;
      if (this.zhao == 3) {
        this.zhao = 1;
      }
    },
    yushe(cid, name) {
      console.log(name);
      this.yucid = cid;
      this.yusheTit = name;
      this.preIsShow = true;
    },

    jion(cid) {
      console.log("点击了--------");
      this.over++;
      if (this.over == 3) {
        this.over = 1;
      }
      this.cid = cid;
    },
    shua() {
      this.res_joinList(this.datas);
    },
    async dodo(cid, id) {
      if (id == 1) {
        ElMessageBox.confirm("是否确定启用该公司", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            let res = await api.doenable(cid);
            if (res.code == 1) {
              this.$message.success("成功");
              this.res_joinList(this.datas);
            } else {
              // alert(res.message);
              this.$message.warning(res.message);
            }
          })
          .catch(() => {
            this.$message.info("取消操作");
          });
      } else {
        ElMessageBox.confirm("是否确定禁用该公司", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            let res = await api.dodisable(cid);
            if (res.code == 1) {
              this.$message.success("成功");
              this.res_joinList(this.datas);
            } else {
              // alert(res.message);
              this.$message.warning(res.message);
            }
          })
          .catch(() => {
            this.$message.info("取消操作");
          });
      }
    },

    getInitial() {
      for (var i = 0; i < this.count; i++) {
        this.list.push(this.list[i]);
      }
    },
    async serve(cid, id) {
      this.rate++;
      if (this.rate == 3) {
        this.rate = 1;
      }
      let res = await api.priceList(cid, id);
      console.log("合作商服务价格列表：", res.data);
      this.resHeZuo = res.data;
      this.cid = cid;
      //this.priceList = res.data.priceDetail.priceList;
    },

    // 分页器
    prev_view() {
      console.log("---合作上一页", this.datas.page);
    },
    // 分页器
    next_view() {
      console.log("---合作下一页", this.datas.page);
    },
    // 点击页数时触发
    current_view(e) {
      this.datas.page = e;
      this.res_joinList(this.datas);
    },
    call(tel, str) {
      let ue =  this.$store.state.emic;
      let mode = this.$store.state.emicmode;

      console.log("拨打电话", tel);
      ue.call.callout({
        customerNumber:tel.toString(),
        loginType:mode,
        extras:str
      });
    },
    shujubtn() {
      this.shujuyin = true
    },
    guanbi() {
      this.shujuyin = false
    },
    zguanbi() {
      this.zdshow = false
      this.zdcid  = '';
      this.zdname = '';
    },
    gsyj(cid,name){
      console.log(cid);
      this.yijiaoCid = cid;
      this.yijiaoShow = true;
    },
    closeyj()
    {
      this.yijiaoCid = '';
      this.yijiaoShow = false;
    }


  },
  computed: {
    getEmic() {
      return this.$store.state.emic;
    },
    getlook()
    {
      return this.$store.state.lookonly;
    }
  },
  watch: {
    isfresh(newval) {
      console.log("shuaxin");
      this.res_joinList(this.datas);
    },
    bkeyword(newval) {
      this.datas.keyword = newval;
      this.res_joinList(this.datas);
    },
    bsaleman(newval) {
      this.datas.saleman = newval;
      this.res_joinList(this.datas);
    },
    bcompanytype(newval) {
      this.datas.companytype = newval;
      this.res_joinList(this.datas);
    },
    bxieyi(newval) {
      this.datas.xieyi = newval;
      this.res_joinList(this.datas);
    },
    isState(newval) {
      // console.log('啥',newval);
      this.datas.status = newval;
      this.res_joinList(this.datas);
    }
  },

  beforeMount() {
    // 在页面挂载前就发起请求
    this.getInitial();
  },
};
</script>

<style lang="less" scoped>
table {
  padding: 0 30px 0 0;
}
.hand {
  width: 1610px;
  box-sizing: border-box;
  background-color: #f5f5f5;
}
.number,
.collaborate,
.owner,
.charge,
.state,
.found,
.team,
.operate {
  text-align: left;
  height: 19px;
  font-size: 14px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 0px;
  color: #2a3346;
  opacity: 1;
}
th {
  width: 200px;
}
.number {
  padding-left: 30px;
  padding-right: 142px;
  //   width: 28px;
}
.collaborate {
  padding-right: 10px;
  //   width: 119px;
}
.owner {
  padding-right: 141px;
  //   width: 105px;
}
.charge {
  padding-right: 80px;
  //   width: 56px;
}
.state {
  padding-right: 84px;
  //   width: 56px;
}
.found {
  padding-right: 100px;
  //   width: 91px;
}
.team {
  padding-right: 93px;
  //   width: 70px;
}
.operate {
  padding-right: 200px;
  //   width: 28px;
}
.td-number {
  padding-left: 30px;
  padding-top: 25px;
  padding-bottom: 13px;
  width: 116px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 32px;
  color: #727e96;
  opacity: 1;
}
.td-collaborate {
  padding-top: 25px;
  padding-bottom: 13px;
  width: 174px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 32px;
  color: #727e96;
  opacity: 1;
}
tr:hover {
  background-color: #f9fafe !important;
}
.bg_color {
  background-color: #ffffff !important;
}

.bg_color2 {
  background-color: #fafafa !important;
}

i {
  font-style: normal;
  color: #2c68ff;
}

strong {
  display: inline-block;
  //margin-top: 20px;
  color: #2a3346;
}
span {
  display: inline-block;
  margin-top: 20px;
}
.td-charge,
.td-found {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #727e96;
  opacity: 1;
}
.td-charge {
  width: 290px;
  display: flex;
  align-items: center;
  height: 70px;
  box-sizing: border-box;
}
.s1,
.s2,
.s3 {
  width: 80px;
  height: 32px;
  box-sizing: border-box;
  opacity: 1;
  font-size: 14px;
  border-radius: 2px;
  text-align: center;
  line-height: 32px;
}
.s1 {
  background: #e8f3fe;
  color: #2c68ff;
}
.s2 {
  color: #27cc8b;
  background-color: #eefbf4;
}
.s3 {
  background-color: #fff6e9;
  color: #ff9100;
}
.check,
.know,
.know1,
.visit,
.complain,
.yingxiao {
  display: inline-block;
  padding-top: 19px;
  padding-bottom: 13px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 32px;
  color: #2c68ff;
  opacity: 1;
  margin-right: 10px;
  cursor: pointer;
}

.know1 {
  // width: 48px;
  color: #ff505a;
  cursor: pointer;
}
.visit,
.complain {
  // width: 65px;
  cursor: pointer;
}

.tiao {
  display: inline-block;
  // width: 0px;
  height: 14px;
  margin-right: 10px;
  box-sizing: border-box;
  border: 1px solid #dadfe6;
  opacity: 1;
}
.main {
  width: 1670px;
  height: 690px;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  // background-color: skyblue;
}

.jinXing-sorter {
  position: absolute;
  bottom: 30px;
  right: 30px;
  padding: 30px;
}

.xieyi-tixing {
  position: absolute;
  bottom: 30px;
  left: 30px;
  padding: 30px;
  display: flex;
  
}
.xieyi-label{
  width: 100px;
  height: 28px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 20px;
  color: #2C68FF;
  line-height: 22px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}
.xieyi-tixing b{
  color:#000;
  padding:0px 5px;
  cursor: pointer;
}
.xieyi-t1,
.xieyi-t2,
.xieyi-t3,
.xieyi-t4,
.xieyi-t5{
  width: 140px;
  height: 28px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 20px;
  color: #FF950A;
  line-height: 22px;
  text-align: center;
  font-style: normal;
  text-transform: none;
}

.xieyi-t2{
  color:#FF525C;
}
.xieyi-t3{
  color:#888888;
}
.xieyi-t4{
  color:#000000;
}
.xieyi-t5{
  color:#27c527;
}
.hezuobox {
  width: 1610px;
  box-sizing: border-box;
}
.hezuobox /deep/ .el-table__inner-wrapper {
  width: 100%;
}
.hezuobox /deep/ .el-table-fixed-column--right:last-of-type {
  // width: 590px;
  width: 750px;
  display: flex;
  align-items: center;
  height: 70px;
}
.hezuobox /deep/ .el-table th.el-table__cell.is-leaf {
  background-color: #f5f5f5;
  height: 50px;
  color: #000;
}
.hezuobox /deep/ .el-table__row {
  height: 70px !important;
  box-sizing: border-box;
}

.hezuobox /deep/ .el-table__cell {
  padding: 0;
}

.hezuobox /deep/ .el-button--small {
  width: 60px !important;
}
.dingbtn{
  margin-left: 10px;
}

.el-button+.el-button {
  margin-left: 5px;
}

.call {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: url("../assets/img/index/call.png");
  background-size: contain;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  vertical-align: middle;
  margin-left: 5px;
}
.carcall {
  display: flex;
  align-items: center;
}
i,
.xiangqing {
  font-style: normal;
  color: #2c68ff;
}
.xiangqing {
  cursor: pointer;
}

.export2{
  float: right;
  width: 130px;
  height: 50px;
  margin-top: 30px;
  margin-right: 30px;
  border: 1px solid #29CC8C;
  opacity: 1;
  cursor: pointer;
  border-radius: 2px;
  cursor: pointer;
  background: #29CC8C;
}

.el-button{
  margin-left:0px !important;
  margin-right:6px !important;
  margin-bottom:5px !important;
}

.splitbox {
  float: left;
  width: 1920px;
  height: auto;
  background: #000000;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 0px;
  position: absolute;
  top: -80px;
  left: -192px;
  z-index: 70;
}
.main {
  width: 520px;
  //   height: 310px;
  background-color: #fff;
  margin: 340px auto;
  border-radius: 8px;
  padding: 28px;
  box-sizing: border-box;
}
.splitnav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.splitit {
  font-size: 20px;
  color: #171746;
  font-weight: bold;
}

.splitbtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.save,
.xiao {
  width: 80px;
  height: 32px;
  cursor: pointer;
  opacity: 1;
  border-radius: 4px;
}
.save {
  border: none;
  background: #2c68ff;
  color: #fff;
  margin-left: 20px;
}
.xiao {
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  border-radius: 2px;
}
.xiao:hover {
  color: #2c68ff;
  border: 1px solid #2c68ff;
}
.xuanbox {
  margin-top: 20px;
}
.xuantime {
  font-size: 14px;
  color: #44444f;
  margin-right: 20px;
}
.xuanbox /deep/ .el-range-editor.el-input__inner {
  width: 350px;
}
.tishi {
  text-align: center;
  font-size: 14px;
  color: #b5b5be;
  margin-top: 20px;
}
</style>
